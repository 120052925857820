import React from "react";
import { Container, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

import torba_papierowa_z_uchem from "../images/torby_papierowe/uchwyt_plaski_320_170_290.png";
import bagietka from "../images/torebki_papierowe/bagietka_270_120_50.jpg";
import torebka_krzyzowa from "../images/torebki_krzyzowe/torebka_krzyzowa.jpg";
import karton_transportowy from "../images/kartony_transportowe/karton_transportowy_25_15_8.jpeg";
import patera_aluminiowa from "../images/tace_kateringowe/patera_aluminiowa.jpg";
import pojemnik_nd from "../images/pojemniki_do_zgrzewu/pojemnik_nd.jpg";
import folia_pod_zgrzew from "../images/pojemniki_do_zgrzewu/folia_pod_zgrzew.jpeg";
import menubox_nd from "../images/pojemniki_styropianowe/menubox_nd.png";
import zaupa_500 from "../images/zupy/zupka_500.jpg";
import kubek_papierowy_kraft from "../images/kubki_papierowe/kubek.jpg";
import kraft from "../images/pojemniki_kraft/kraft.jpg";
import chinet_23 from "../images/talerze/chinet_23.jpg";
import zloty_krazek from "../images/podklady_pod_tort/zloty_krazek.jpeg";
import pizza from "../images/pizza/pizza.jpg";
import folia_stretch_czarna from "../images/opakowania_przemyslowe/folia_stretch_czarna.jpg";
import SingleProduct from "./SingleProduct.js";
import wiaderko from "../images/wiaderka/wiaderko_1l.jpg";
import f80 from "../images/pojemniczki/f80.jpg";
import tort from "../images/pudelka_na_torty/tort.jpg";
import R59 from "../images/foremki_aluminiowe/R59.jpg";
import salatka_diament from "../images/pojemniki_salatkowe/salatka_diament.jpg";
import pape from "../images/torebki_prozniowe/pape.jpg";
import nitryle_niebieskie from "../images/rekawiczki/nitryle_niebieskie.jpg";
import reklamowka from "../images/reklamowki/zolta.jpg";
import serwetka from "../images/serwetki/czerwona.jpg";
import miseczka_chinet from "../images/miseczki/miseczka_chinet.jpg";
import podajnik_zz from "../images/art_gospodarcze/podajnik_zz.png";
import fartuch_fizelinowy from "../images/odziez/fartuch_fizelinowy.jpg";
import ajax5l from "../images/chemia_gospodarcza/ajax5l.jpg";
import sus1 from "../images/pudelka_sus/sus1.png";
import mop_gosia from "../images/sprzatanie/mop_gosia.jpg";
import maxi_makulatura from "../images/reczniki/maxi_makulatura.jpg";
import karton_klapowy from "../images/kartony_klapowe/karton_klapowy.jpg";
import hamburger_gigant from "../images/opakowania_na_zywnosc/hamburger_gigant.jpg";
import kubek_pet from "../images/shake/pet.jpg";
import woreczek_celofanowy from "../images/woreczki_celofonowe/woreczek_celofanowy.jpg";

import { Helmet } from "react-helmet";

export default function Products() {
  return (
    <div className="col container_column">
      <Helmet>
        <title>Produkty - Hurtownia Opakowań Henryk Klimowicz</title>
        <meta
          name="description"
          content="Zobacz nasze produkty: torby i torebki papierowe, opakowania jednorazowe, sztućce plastikowe, serwetki."
        />
        <link rel="canonical" href="/produkty" />
      </Helmet>

      <div className="row justify-content-center p-4">
        <SingleProduct
          link_to_products="/torby-papierowe-z-uchem"
          image_of_product={torba_papierowa_z_uchem}
          description="Torby papierowe z uchem"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/torebki-papierowe"
          image_of_product={bagietka}
          description="Torebki papierowe"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/torebki-krzyzowe"
          image_of_product={torebka_krzyzowa}
          description="Torebki krzyżowe"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/kartony-transportowe"
          image_of_product={karton_transportowy}
          description="Kartony transportowe"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/tace-kateringowe"
          image_of_product={patera_aluminiowa}
          description="Tace kateringowe"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/pojemniki-pod-zgrzew"
          image_of_product={pojemnik_nd}
          description="Pojemniki pod zgrzew, przykrywki, folie"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/pojemniki-okragle-pod-zgrzew"
          image_of_product={zaupa_500}
          description="Pojemniki okrągłe"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/przykrycia-do-pojemnikow-pod-zgrzew"
          image_of_product={folia_pod_zgrzew}
          description="Przykrycia do pojemników pod zgrzew"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/pojemniki-styropianowe"
          image_of_product={menubox_nd}
          description="Pojemniki obiadowe"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/kubeczki-papierowe"
          image_of_product={kubek_papierowy_kraft}
          description="Kubeczki papierowe przykrywki, owijki"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/pojemniki-kraft"
          image_of_product={kraft}
          description="Pojemniki kraft"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/talerze"
          image_of_product={chinet_23}
          description="Talerze i tacki"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/podklady-pod-tort"
          image_of_product={zloty_krazek}
          description="Podkłady pod tort"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/pizza"
          image_of_product={pizza}
          description="Kartony na pizze"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/opakowania-przemyslowe"
          image_of_product={folia_stretch_czarna}
          description="Opakowania przemysłowe"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/wiaderka"
          image_of_product={wiaderko}
          description="Wiaderka do żywności"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/pojemniki-cukiernicze"
          image_of_product={f80}
          description="Pojemniki cukiernicze"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/pudelka-na-torty"
          image_of_product={tort}
          description="Pudelka na torty"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/foremki-aluminiowe"
          image_of_product={R59}
          description="Foremki aluminiowe"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/pojemniki-salatkowe"
          image_of_product={salatka_diament}
          description="Pojemniki sałatkowe"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/torebki-prozniowe"
          image_of_product={pape}
          description="Torebki próżniowe"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/rekawiczki"
          image_of_product={nitryle_niebieskie}
          description="Rękawiczki"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/reklamowki-foliowe"
          image_of_product={reklamowka}
          description="Reklamówki foliowe"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/serwetki"
          image_of_product={serwetka}
          description="Serwetki"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/miseczki-na-zupe"
          image_of_product={miseczka_chinet}
          description="Miseczki na zupę"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/artykuly-gospodarcze"
          image_of_product={podajnik_zz}
          description="Artykuły gospodarcze"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/odziez-gastronomiczna"
          image_of_product={fartuch_fizelinowy}
          description="Odzież gastronomiczna"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/chemia-gospodarcza"
          image_of_product={ajax5l}
          description="Chemia gospodarcza"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/pudelka-na-sushi"
          image_of_product={sus1}
          description="Pudełka na sushi"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/sprzatanie"
          image_of_product={mop_gosia}
          description="Sprzątanie"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/reczniki"
          image_of_product={maxi_makulatura}
          description="Ręczniki"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/kartony-klapowe"
          image_of_product={karton_klapowy}
          description="Kartony klapowe/paczkomatowe"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/kartoniki-koperty-owijki"
          image_of_product={hamburger_gigant}
          description="Kartoniki, kubełki, koperty na żywność"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/shake-lody"
          image_of_product={kubek_pet}
          description="Kubki shake, miski na lody"
        ></SingleProduct>
        <SingleProduct
          link_to_products="/woreczki-celofanowe"
          image_of_product={woreczek_celofanowy}
          description="Woreczki celofanowe"
        ></SingleProduct>
      </div>
    </div>
  );
}
