import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import ProductHeader from "./ProductHeader.js";
import { Helmet } from "react-helmet";

import { products as products1 } from "../products/miseczki_na_zupe_data.js";
import { products as products2 } from "../products/opakowania_przemyslowe_data.js";
import { products as products3 } from "../products/pojemniki_salatkowe_data.js";
import { products as products4 } from "../products/pudelka_na_tort_data.js";
import { products as products5 } from "../products/rekawiczki_data.js";
import { products as products6 } from "../products/reklamowki_data.js";
import { products as products7 } from "../products/serwetki_data.js";
import { products as products8 } from "../products/torebki_prozniowe_data.js";
import { products as products9 } from "../products/pizza_data.js";
import { products as products10 } from "../products/kartony_transportowe_data.js";
import { products as products11 } from "../products/kubki_papierowe_data.js";
import { products as products12 } from "../products/podklady_pod_tort.js";
import { products as products13 } from "../products/pojemniki_cukiernicze_data.js";
import { products as products14 } from "../products/pojemniki_kraft_data.js";
import { products as products15 } from "../products/pojemniki_okragle_pod_zgrzew_data.js";
import { products as products16 } from "../products/pojemniki_pod_zgrzew_data.js";
import { products as products17 } from "../products/pojemniki_styropianowe_data.js";
import { products as products18 } from "../products/przykrycia_do_zgrzewu_data.js";
import { products as products19 } from "../products/tace_kateringowe_data.js";
import { products as products20 } from "../products/talerze_data.js";
import { products as products21 } from "../products/torby_papierowe_ucho_data.js";
import { products as products22 } from "../products/torebki_krzyzowe_data.js";
import { products as products23 } from "../products/torebki_papierowe_data.js";
import { products as products24 } from "../products/wiaderka_data.js";
import { products as products25 } from "../products/foremki_aluminiowe_data.js";
import { products as products26 } from "../products/chemia_gospodarcza.js";
import { products as products27 } from "../products/odziez_data.js";
import { products as products28 } from "../products/art_gospodarcze.js";
import { products as products29 } from "../products/pudelka_sushi_data.js";
import { products as products30 } from "../products/sprzatanie_data.js";
import { products as products31 } from "../products/reczniki_data.js";
import { products as products32 } from "../products/kartony_klapowe_data.js";
import { products as products33 } from "../products/opakowania_na_zywnosc.js";
import { products as products34 } from "../products/shake_data.js";
import { products as products35 } from "../products/woreczki_celofanowe_data.js";

export default function Szukaj() {
  const [products, setProducts] = useState([
    ...products1,
    ...products2,
    ...products3,
    ...products4,
    ...products5,
    ...products6,
    ...products7,
    ...products8,
    ...products9,
    ...products10,
    ...products11,
    ...products12,
    ...products13,
    ...products14,
    ...products15,
    ...products16,
    ...products17,
    ...products18,
    ...products19,
    ...products20,
    ...products21,
    ...products22,
    ...products23,
    ...products24,
    ...products25,
    ...products26,
    ...products27,
    ...products28,
    ...products29,
    ...products30,
    ...products31,
    ...products32,
    ...products33,
    ...products34,
    ...products35,
  ]);
  const [show, setShow] = useState(false);
  const [image_to_display, set_image_to_display] = useState();
  const handleShow = (image_to_set) => {
    set_image_to_display(image_to_set);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const map_products = (products_to_map) => {
    return products_to_map.map((product) => (
      <Card style={{ width: "18rem", margin: 20 }}>
        <Button
          variant="image"
          onClick={(e) => handleShow(product.image)}
          style={{ padding: 0 }}
        >
          <Card.Img
            variant="top"
            style={{ marginTop: 10 }}
            src={product.image}
            alt={product.name}
          />
        </Button>
        <Card.Body>
          <hr />
          <Card.Title>{product.name}</Card.Title>
          {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
      </Card>
    ));
  };

  const [list_of_products, set_list_of_products] = useState();

  const searchProduct = (e) => {
    var data = e.target.value.toUpperCase();
    var p = [];
    if (data.length > 2) {
      var x = products;
      p = x.filter((product) => product.name.toUpperCase().includes(data));
    }

    set_list_of_products(map_products(p));
  };

  return (
    <div className="col container_column">
      <Helmet>
        <title>Miseczki na zupę - Hurtownia Opakowań Henryk Klimowicz</title>
        <meta name="description" content="Miseczki i pojemniki na zupę." />
        <link rel="canonical" href="/miseczki-na-zupe" />
      </Helmet>

      {/* <div className="row mt-3 product-header-col"> */}

      <div className="row justify-content-center">
        <Form.Control
          className="xxx"
          type="search"
          placeholder="Wyszukaj produkt..."
          aria-label="Szukaj"
          onChange={searchProduct}
        />

        {/* <form action="" className="search-bar">
          <input
            type="text"
            //   className="xxx"
            placeholder="Search here"
            onChange={searchProduct}
          />
        </form> */}
      </div>

      {/* </div> */}

      <div className="row justify-content-center">{list_of_products}</div>

      <Modal show={show} onHide={handleClose} centered size="xl">
        <img src={image_to_display} className="img-fluid" alt="wzór" />
      </Modal>
    </div>
  );
}
