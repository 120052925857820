import woreczek_celofanowy from "../images/woreczki_celofonowe/woreczek_celofanowy.jpg";

const products = [
  {
    id: 0,
    name: "Woreczek celofanowy 10x15",
    width: 10,
    height: 15,
    amount: "100 szt.",
    image: woreczek_celofanowy,
  },
  {
    id: 1,
    name: "Woreczek celofanowy 12x18",
    width: 12,
    height: 18,
    amount: "100 szt.",
    image: woreczek_celofanowy,
  },
  {
    id: 2,
    name: "Woreczek celofanowy 15x20",
    width: 15,
    height: 20,
    amount: "100 szt.",
    image: woreczek_celofanowy,
  },
  {
    id: 3,
    name: "Woreczek celofanowy 20x30",
    width: 20,
    height: 30,
    amount: "100 szt.",
    image: woreczek_celofanowy,
  },
  {
    id: 4,
    name: "Woreczek celofanowy 25x35",
    width: 25,
    height: 35,
    amount: "100 szt.",
    image: woreczek_celofanowy,
  },
  {
    id: 5,
    name: "Woreczek celofanowy 35x45",
    width: 35,
    height: 45,
    amount: "100 szt.",
    image: woreczek_celofanowy,
  },
  {
    id: 6,
    name: "Woreczek celofanowy 40x50",
    width: 40,
    height: 50,
    amount: "100 szt.",
    image: woreczek_celofanowy,
  },
];

export { products };
