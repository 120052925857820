import kuferek_21_21_12 from "../images/pudelka_na_torty/kuferek_21_21_12.jpg";
import tort from "../images/pudelka_na_torty/tort_165_110_80.jpg";
import tort_165_110_80 from "../images/pudelka_na_torty/tort.jpg";
import tort_32_32_14 from "../images/pudelka_na_torty/tort_32_32_14.png";
import tort_42_32_15 from "../images/pudelka_na_torty/tort_42_32_15.png";
import tort_bez_okienka from "../images/pudelka_na_torty/tort_bez_okienka.jpg";
import tort_pojemnik from "../images/pudelka_na_torty/tort_pojemnik.jpg";
import pudelko_125_210_70 from "../images/pudelka_na_torty/pudelko_125_210_70.png";
import pudelko_150_150_100 from "../images/pudelka_na_torty/pudelko_150_150_100.jpg";

const products = [
  {
    id: 1,
    name: "Kuferek na tort 210x210x120",
    width: 210,
    length: 210,
    height: 120,
    color: "Biały",
    window: "nie",
    amount: 25,
    type: "pudelko",
    image: kuferek_21_21_12,
  },
  {
    id: 2,
    name: "Kuferek na tort 260x260x140",
    width: 260,
    length: 260,
    height: 140,
    color: "Biały",
    window: "nie",
    amount: 25,
    type: "pudelko",
    image: kuferek_21_21_12,
  },
  {
    id: 3,
    name: "Kuferek na tort 300x300x110",
    width: 300,
    length: 300,
    height: 110,
    color: "Biały",
    window: "nie",
    amount: 25,
    type: "pudelko",
    image: kuferek_21_21_12,
  },
  {
    id: 4,
    name: "Pudełko na tort 165x110x80",
    width: 165,
    length: 110,
    height: 80,
    color: "Biały",
    window: "tak",
    amount: 50,
    type: "pudelko",
    image: tort,
  },
  {
    id: 5,
    name: "Pudełko na tort 310x220x180",
    width: 310,
    length: 220,
    height: 180,
    color: "Biały",
    window: "tak",
    amount: 50,
    type: "pudelko",
    image: tort_165_110_80,
  },
  {
    id: 6,
    name: "Pudełko na tort 220x220x110",
    width: 220,
    length: 220,
    height: 110,
    color: "Biały",
    window: "tak",
    amount: 50,
    type: "pudelko",
    image: tort_165_110_80,
  },
  {
    id: 7,
    name: "Pudełko na tort 320x320x140",
    width: 320,
    length: 320,
    height: 140,
    color: "Biały",
    window: "tak",
    amount: 50,
    type: "pudelko",
    image: tort_32_32_14,
  },
  {
    id: 8,
    name: "Pudełko na tort 420x320x150",
    width: 420,
    length: 320,
    height: 150,
    color: "Biały",
    window: "nie",
    amount: 10,
    type: "pudelko",
    image: tort_42_32_15,
  },
  {
    id: 9,
    name: "Pudełko na tort 280x280x100",
    width: 280,
    length: 280,
    height: 100,
    color: "Biały",
    window: "nie",
    amount: 25,
    type: "pudelko",
    image: tort_bez_okienka,
  },
  {
    id: 10,
    name: "Pudełko na tort 220x220x120",
    width: 220,
    length: 220,
    height: 120,
    color: "Biały",
    window: "nie",
    amount: 50,
    type: "pudelko",
    image: tort_bez_okienka,
  },
  {
    id: 11,
    name: "Pudełko na tort 310x220x80",
    width: 310,
    length: 220,
    height: 80,
    color: "Biały",
    window: "nie",
    amount: 15,
    type: "pudelko",
    image: tort_bez_okienka,
  },
  {
    id: 12,
    name: "Pojemnik na tort 1025",
    diameter: 185,
    height: 89,
    amount: 25,
    type: "pojemnik",
    image: tort_pojemnik,
  },
  {
    id: 13,
    name: "Pojemnik na tort 1845",
    diameter: 250,
    height: 116,
    amount: 25,
    type: "pojemnik",
    image: tort_pojemnik,
  },
  {
    id: 14,
    name: "Pojemnik na tort 1045",
    diameter: 296,
    height: 112,
    amount: 25,
    type: "pojemnik",
    image: tort_pojemnik,
  },
  {
    id: 15,
    name: "Pojemnik na tort 1056",
    diameter: 360,
    height: 131,
    amount: 25,
    type: "pojemnik",
    image: tort_pojemnik,
  },
  {
    id: 16,
    name: "Pudełko na tort/ciasto 125x210x70",
    width: 125,
    length: 210,
    height: 70,
    color: "Biały",
    window: "nie",
    amount: 20,
    type: "pudelko",
    image: pudelko_125_210_70,
  },
  {
    id: 17,
    name: "Pudełko na tort/ciasto 150x150x100",
    width: 150,
    length: 150,
    height: 100,
    color: "Biały",
    window: "nie",
    amount: 30,
    type: "pudelko",
    image: pudelko_150_150_100,
  },
  {
    id: 18,
    name: "Pudełko na tort/ciasto 140x140x60",
    width: 140,
    length: 140,
    height: 60,
    color: "Biały",
    window: "nie",
    amount: 50,
    type: "pudelko",
    image: pudelko_150_150_100,
  },
];

export { products };
